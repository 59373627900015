<template >
  <v-container style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="mt-10 d-flex justify-center">
      <v-col cols="6" style="max-width: 500px;">
        <v-card class="pa-4">

          <v-img :src="require('../assets/logo.png')" class="my-3 mx-auto" contain
            :style="{ 'width': isResponsive ? '60%' : '100%' }" height="200" />
          <div>
            <span class=" d-flex justify-center">
              <h1 style="color:#1e517b">אזור אישי זיהוי שיחה חסויה</h1>
            </span>
          </div>
          <v-form @submit.prevent="submitHandler" ref="form">
            <v-card-text>
              <v-text-field v-model="phone" label="טלפון" hide-spin-buttons type="number" :rules="[v => !!v || 'שדה חובה']"
                placeholder="טלפון" required></v-text-field>
              <v-text-field v-model="password" label="סיסמה" type="password" :rules="[v => !!v || 'שדה חובה']"
                placeholder="סיסמה" required></v-text-field>

            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn @click="login" color="#635ddb">
                <span class="white--text px-8">כניסה</span>
              </v-btn>
            </v-card-actions>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="4000">
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn :color="snackbarColorBt" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
export default {

  data: () => ({
    isResponsive: false,
    phone: "",
    password: "",
    textColor: "red",
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
  }),
  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.progressShow = true
        let api = process.env.VUE_APP_HISTORY_URL;

        var formdata = new FormData();
        formdata.append("clid", this.phone);
        formdata.append("auth_pass", this.password);
        formdata.append("api_key", process.env.VUE_APP_API_KEY);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch(api, requestOptions)
          .then(response => {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          }).then(result => {
            // console.log(result);
             if (result.includes("username or password inccorect")) {
              this.showSnackBar("שגיאת התחברות, שם משתמש או סיסמה לא תקינים", "red");
              this.progressShow = false;
            } else {
              this.$router.push({ name: 'calls', params: { phone: this.phone , password: this.password, calls: result } })
              this.progressShow = false;

            }
          }).catch(error => {
            console.log('error', error)
          });
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    handleResize() {
        // Check if the window width is less than or equal to 600px
        if (window.innerWidth <= 500) {
          this.isResponsive = true;
        } else {
          this.isResponsive = false;
        }
      },
  },
  watch: {
    $route() {
      document.title = 'Login | Merkaz-hakavim'
    },
    },
    beforeDestroy() {
      // Remove event listener before component is destroyed
      window.removeEventListener('resize', this.handleResize);
    },
  mounted() {
    document.title = 'Login | Merkaz-hakavim'
    if (window.innerWidth <= 500) {
        this.isResponsive = true; // Set to true if screen size is small
      }
      window.addEventListener('resize', this.handleResize);
  },
}
</script>
<style>
@media (max-width: 500px) {
  .d-flex.justify-space-around {
    flex-direction: column;
    align-items: center;
  }

  .d-flex.justify-space-around>* {
    margin-bottom: 1rem;
  }

  .d-flex.flex-wrap {
    flex-wrap: wrap;
  }

  .d-flex.flex-wrap>* {
    flex-basis: 100%;
    margin-bottom: 10px;
    /* Add margin between lines */
  }

  h1 {
    font-size: 1.2em;
  }
}

@media (min-width: 501px) {
  h1 {
    font-size: 1.5em;
  }
}

.filtered-calls-blocked {
  font-weight: bold;
}
</style>

<!-- // filteredCalls() {
  //   return this.calls.filter(call => call.did === 'blocked').map(call => {
  //     return {
  //       ...call,
  //       did: 'חסוי',
  //       disposition: call.disposition === 'ANSWERED' ? 'נענתה' :
  //         call.disposition === 'FAILED' ? 'נכשלה' :
  //           call.disposition === 'NO ANSWER' ? 'לא נענתה' :
  //             call.disposition === 'BUSY' ? 'תפוס' :
  //               call.disposition,
  //       dst: call.dst === 'vm' ? 'תא קולי' : call.dst
  //     };
  //   })
  // } -->

  <!-- filteredCalls() {
    return this.calls.map(call => {
      return {
        ...call,
        did: call.did === 'blocked' ? 'חסוי' : call.did === 'open' ? 'מזוהה' : call.did,
        disposition: call.disposition === 'ANSWERED' ? 'נענתה' :
          call.disposition === 'FAILED' ? 'נכשלה' :
            call.disposition === 'NO ANSWER' ? 'לא נענתה' :
              call.disposition === 'BUSY' ? 'תפוס' :
                call.disposition,
        dst: call.dst === 'vm' ? 'תא קולי' : call.dst === 'open' ? 'מזוהה' : call.dst,
      };
    })
  } -->


  <!-- if (!recordingfile) {
    return false;
  }
  try {
    const res = await fetch(`http://172.104.250.239/sx/public/uploads/recs/${recordingfile}`, { method: 'HEAD' });
     this.data = res.status !== 404;
    // return response.status !== 404;
        } catch (error) {
    console.error(error);
    return false;
  } -->