<template>
  <div>
    <FormLogin/>
  </div>
</template>
<script>
import FormLogin from '@/components/FormLogin.vue'
export default {
  name: 'login',
  components: {
    FormLogin
  }
}
</script>
